import PhotoValdemarBaran from "../src/assets/photos/team-valdemar-baran.jpg";
import PhotoIndreGriskiene from "../src/assets/photos/team-indre-griskiene.jpg";
import PhotoDovileBedouet from "../src/assets/photos/team-dovile-bedouet.jpg";
import PhotoSamantaPargaliauskaite from "../src/assets/photos/team-samanta-pargaliauskaite.jpg";
import PhotoViktorasMajevskis from "../src/assets/photos/team-viktoras-majevskis.jpg";
import PhotoMartaSubataite from "../src/assets/photos/team-marta-subataite.jpg";
import PhotoMonikaStankeviciute from "../src/assets/photos/team-monika-stankeviciute.jpg";
import PhotoMarijaSaraite from "../src/assets/photos/team-marija-saraite.jpg";
import PhotoPauliusKlimovas from "../src/assets/photos/team-paulius-klimovas.jpg";
import PhotoZivilePaskonyte from "../src/assets/photos/team-zivile-paskonyte.jpg";
import PhotoIngaBurneikiene from "../src/assets/photos/team-inga-burneikiene.jpg";
import PhotoTomasGriska from "../src/assets/photos/team-tomas-griska.jpg";
import PhotoDziugasVysniauskas from "../src/assets/photos/team-dziugas-vysniauskas.jpg";
import PhotoJonasBiciunas from "../src/assets/photos/team-jonas-biciunas.jpg";

export const TEAM = [
  {
    key: "valdemar",
    name: "Valdemar Baran",
    photo: PhotoValdemarBaran,
    position: "executiveMale"
  },

  // legal

  {
    key: "samanta",
    name: "Samanta Pargaliauskaitė",
    photo: PhotoSamantaPargaliauskaite,
    position: "legal"
  },
  {
    key: "viktoras",
    name: "Viktoras Majevskis",
    photo: PhotoViktorasMajevskis,
    position: "legal"
  },

  // coms

  {
    key: "indre",
    name: "Indrė Griškienė",
    photo: PhotoIndreGriskiene,
    position: "coms"
  },

  // coordination

  {
    key: "dovile",
    name: "Dovilė Bedouet",
    photo: PhotoDovileBedouet,
    position: "coordinatorFemale"
  },
  {
    key: "paulius",
    name: "Paulius Klimovas",
    photo: PhotoPauliusKlimovas,
    position: "coordinatorMale"
  },
  {
    key: "zivile",
    name: "Živilė Paškonytė",
    photo: PhotoZivilePaskonyte,
    position: "coordinatorFemale"
  },
  {
    key: "inga",
    name: "Inga Burneikienė",
    photo: PhotoIngaBurneikiene,
    position: "coordinatorFemale"
  },

  // it

  {
    key: "tomas",
    name: "Tomas Griška",
    photo: PhotoTomasGriska,
    position: "it"
  },
  {
    key: "dziugas",
    name: "Džiugas Vyšniauskas",
    photo: PhotoDziugasVysniauskas,
    position: "it"
  },
  {
    key: "jonas",
    name: "Jonas Bičiūnas",
    photo: PhotoJonasBiciunas,
    position: "it"
  },

  // revision

  {
    key: "monika",
    name: "Monika Stankevičiūtė",
    photo: PhotoMonikaStankeviciute,
    position: "revision"
  },
  {
    key: "marta",
    name: "Marta Subataitė",
    photo: PhotoMartaSubataite,
    position: "revision"
  },
  {
    key: "marija",
    name: "Marija Šaraitė",
    photo: PhotoMarijaSaraite,
    position: "revision"
  }
];
